export type ResizeListenerCallback = (
  entry: ResizeObserverEntry,
  observer: ResizeObserver,
) => void;

export type ResizeListener = {
  observer: ResizeObserver;
  subscribe: (target: HTMLElement, callback: ResizeListenerCallback) => void;
  unsubscribe: (target: HTMLElement, callback: ResizeListenerCallback) => void;
};

let resizeListener: ResizeListener;
const createResizeListener = (): ResizeListener => {
  const callbacksByTarget = new Map<Element, Set<ResizeListenerCallback>>();

  const resizeObserver = new ResizeObserver(function resizeObserverCallback(
    entries,
    observer,
  ) {
    for (const entry of entries) {
      const callbacks = callbacksByTarget.get(entry.target);

      callbacks?.forEach((cb) => cb(entry, observer));
    }
  });

  return {
    observer: resizeObserver,
    subscribe(target, callback) {
      resizeObserver.observe(target);

      const callbacks = callbacksByTarget.get(target) || new Set();
      callbacks.add(callback);
      callbacksByTarget.set(target, callbacks);
    },
    unsubscribe(target, callback) {
      const callbacks = callbacksByTarget.get(target);
      if (!callbacks) {
        return;
      }

      callbacks.delete(callback);

      if (callbacks.size === 0) {
        resizeObserver.unobserve(target);
        callbacksByTarget.delete(target);
      } else {
        callbacksByTarget.set(target, callbacks);
      }
    },
  };
};

/**
 * We use single instance of ResizeObserver for all element since it's more performant
 * @see https://github.com/WICG/resize-observer/issues/59
 */
export const getResizeListener = (): ResizeListener => {
  if (!resizeListener) {
    resizeListener = createResizeListener();
  }

  return resizeListener;
};
