import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuProps,
} from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';
import { forwardRef, useRef } from 'react';
import { TabNavIndicatorProvider } from './tab-nav-context';
import {
  tabNavIndicator,
  tabNavList,
  tabNavListNoBorder,
  tabNavListSize,
} from './tab-nav.css';

export type TabNavProps = NavigationMenuProps & {
  listClassName?: string;
  size?: 'small' | 'large';
  noBorder?: boolean;
};

export const TabNav = forwardRef<HTMLDivElement, TabNavProps>(function TabNav(
  { children, className, listClassName, size = 'large', noBorder, ...props },
  ref,
) {
  const indicatorRef = useRef<HTMLDivElement>(null);

  return (
    <NavigationMenu className={clsx(className)} ref={ref} {...props}>
      <NavigationMenuList
        className={clsx(
          tabNavList,
          tabNavListSize[size],
          listClassName,
          noBorder && tabNavListNoBorder,
        )}
      >
        <div ref={indicatorRef} className={tabNavIndicator} />

        <TabNavIndicatorProvider value={indicatorRef}>
          {children}
        </TabNavIndicatorProvider>
      </NavigationMenuList>
    </NavigationMenu>
  );
});
