import { RefObject, useLayoutEffect } from 'react';
import { flushSync } from 'react-dom';
import { getRefValue } from './get-ref-value';
import { useCallbackRef } from './use-callback-ref';
import { getResizeListener, ResizeListenerCallback } from './resize-listener';

/**
 * React hook to observe provided element with ResizeObserver
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 */
export const useResizeObserver = <TargetElement extends HTMLElement>(
  /** React ref created by `useRef()` or an HTML element */
  target: RefObject<TargetElement | null> | TargetElement | null,
  /**Invoked with a single `ResizeObserverEntry` any time the `target` resizes */
  callback: ResizeListenerCallback,
): ResizeObserver => {
  const resizeListener = getResizeListener();
  const onResize = useCallbackRef(callback);

  useLayoutEffect(() => {
    let active = true;
    const element = getRefValue(target);
    if (!element) {
      return () => {};
    }

    const observerCallback: ResizeListenerCallback = (entry, observer) => {
      if (active) {
        // In React 18, state updates in a ResizeObserver's callback are happening after the paint which causes flickering
        // when doing some visual updates in it. Using flushSync ensures that the dom will be painted after the states updates happen
        // https://github.com/facebook/react/issues/24331
        flushSync(() => {
          onResize(entry, observer);
        });
      }
    };

    resizeListener.subscribe(element, observerCallback);

    return () => {
      active = false;
      resizeListener.unsubscribe(element, observerCallback);
    };
  }, [onResize, resizeListener, target]);

  return resizeListener.observer;
};
