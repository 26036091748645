import {
  NavigationMenuLinkProps,
  NavigationMenuItem,
  NavigationMenuLink,
} from '@radix-ui/react-navigation-menu';
import { Slottable } from '@radix-ui/react-slot';
import clsx from 'clsx';
import {
  forwardRef,
  useRef,
  useEffect,
  isValidElement,
  cloneElement,
  PropsWithChildren,
} from 'react';
import { useResizeObserver } from '../utils/use-resize-observer';
import {
  tabNavIndicatorWidth,
  tabNavIndicatorMounted,
  tabNavItemContent,
  tabNavItem,
  tabNavLink,
} from './tab-nav.css';
import { useTabNavIndicatorCtx } from './tab-nav-context';

export const TabNavLink = forwardRef<
  HTMLAnchorElement,
  NavigationMenuLinkProps
>(function TabNavLink(
  { children, className, active, asChild, ...linkProps },
  forwardedRef,
) {
  const indicatorRef = useTabNavIndicatorCtx();
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active && itemRef.current && indicatorRef.current) {
      indicatorRef.current.style.transform = `translateX(${itemRef.current.offsetLeft}px) scaleX(${itemRef.current.offsetWidth / tabNavIndicatorWidth})`;
      indicatorRef.current.classList.add(tabNavIndicatorMounted);
    }
  }, [active, indicatorRef]);

  useResizeObserver(itemRef, () => {
    if (active && itemRef.current && indicatorRef.current) {
      indicatorRef.current.style.transform = `translateX(${itemRef.current.offsetLeft}px) scaleX(${itemRef.current.offsetWidth / tabNavIndicatorWidth})`;
      indicatorRef.current.classList.add(tabNavIndicatorMounted);
    }
  });

  const childrenContent =
    asChild && isValidElement<PropsWithChildren>(children)
      ? children.props.children
      : children;

  const content = (
    <div className={tabNavItemContent} ref={itemRef}>
      <Slottable>{childrenContent}</Slottable>
    </div>
  );

  return (
    <NavigationMenuItem className={tabNavItem}>
      <NavigationMenuLink
        ref={forwardedRef}
        className={clsx(className, tabNavLink)}
        active={active}
        asChild={asChild}
        {...linkProps}
      >
        {asChild && isValidElement(children)
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            cloneElement(children, children.props as any, content)
          : content}
      </NavigationMenuLink>
    </NavigationMenuItem>
  );
});
